import Decimal from '@st/decimal'
import type {
  ExchangeMethod,
  ExchangePaymentProvider,
} from '../../../interfaces'
import { useCalypso } from '../../../composables/useCalypso'

interface UseLimitsParams {
  selectedMethod: MaybeRef<ExchangeMethod | undefined>
  currencyId: MaybeRef<number>
  selectedProvider: MaybeRef<ExchangePaymentProvider | undefined>
}

interface UseLimitsReturn {
  minLimit: string
  maxLimit: string
}

export function useLimits({
  selectedMethod,
  currencyId,
  selectedProvider,
}: UseLimitsParams): ComputedRef<UseLimitsReturn> {
  return computed(() => {
    const { getLimitsByCurrencyId } = useCalypso()

    const { min, max } = getLimitsByCurrencyId(unref(currencyId))

    const selectedMethodValue = toValue(selectedMethod)
    if (!selectedMethodValue) {
      return {
        minLimit: min,
        maxLimit: max,
      }
    }

    const provider = selectedMethodValue.providers.find(
      (providers) => providers.provider === toValue(selectedProvider),
    )

    if (!provider) {
      return {
        minLimit: min,
        maxLimit: max,
      }
    }

    if (provider.provider === 'moonpay') {
      return {
        minLimit: Decimal.max(min, provider.minLimit as number).toString(),
        maxLimit: Decimal.min(max, provider.maxLimit as number).toString(),
      }
    }

    const rate = provider?.fixedRate

    const { minLimit, maxLimit } = provider

    const convertedMinLimit = minLimit
      ? new Decimal(minLimit).div(rate).toDecimalPlaces(2).toString()
      : min
    const convertedMaxLimit = maxLimit
      ? new Decimal(maxLimit).div(rate).toDecimalPlaces(2).toString()
      : max

    const foundMinLimit = new Decimal(min).greaterThan(convertedMinLimit)
      ? min
      : convertedMinLimit
    const foundMaxLimit = new Decimal(max).greaterThan(convertedMaxLimit)
      ? convertedMaxLimit
      : max

    return {
      minLimit: foundMinLimit?.toString(),
      maxLimit: foundMaxLimit?.toString(),
    }
  })
}
