<template>
  <div class="deposit" :class="platform" data-t="deposit">
    <NavigationTabs
      v-if="current === 'methods'"
      :type="platform"
      data-t="deposit-navigation-tabs-07my"
    />
    <DepositMethods
      v-if="current === 'methods'"
      :type="platform"
      @handle-currency-selected="handleCurrencySelected"
      @go-to-buy-crypto="goToBuyCrypto"
    />
    <DepositForm
      v-if="current === 'form'"
      :type="platform"
      :selected-currency-id="selectedCurrencyId"
      @back="goToPrevious"
      @handle-connect="handleExternalWalletConnect"
    />
    <ClientOnly v-if="isTonConnectEnabled">
      <ExternalWalletConnectForm
        v-if="current === 'externalWalletConnect'"
        :deposit-address="depositAddress"
        :type="connectType"
        :selected-currency-id="selectedCurrencyId"
        @back="goTo('form')"
      />
      <StDrawer v-model="isExternalWalletDrawerOpened" :full-screen="false">
        <StDrawerHeader
          :title="t('payments.tonConnect.depositFromWallet')"
          disable-router-back
          with-border-bottom
          @close="isExternalWalletDrawerOpened = false"
        />
        <ExternalWalletConnectForm
          :deposit-address="depositAddress"
          :type="connectType"
          :selected-currency-id="selectedCurrencyId"
          @back="isExternalWalletDrawerOpened = false"
        />
      </StDrawer>
    </ClientOnly>
    <BuyCrypto v-if="current === 'buyCrypto'" @go-back="goTo('methods')" />
  </div>
</template>

<script setup lang="ts">
import { useTonConnectEnabled } from '../../composables/useTonConnectEnabled'
import type { ExternalConnectType } from '../../types'
import BuyCrypto from '../BuyCrypto/BuyCrypto.vue'
import { useWalletConnectInit } from '../../composables/useWalletConnectInit'

const { t } = useI18n()
const { platform } = usePlatform()

const isTonConnectEnabled = useTonConnectEnabled()
useWalletConnectInit()

const { goTo, current, goToPrevious } = useStepper([
  'methods',
  'form',
  'externalWalletConnect',
  'buyCrypto',
])

const selectedCurrencyId = ref()

function handleCurrencySelected(id: number) {
  selectedCurrencyId.value = id
  goTo('form')
}

const isExternalWalletDrawerOpened = ref(false)
const depositAddress = ref('')
const connectType = ref<ExternalConnectType>()

function handleExternalWalletConnect(params: {
  address: string
  type: ExternalConnectType
  selectedCurrencyId: number
}) {
  depositAddress.value = params.address
  connectType.value = params.type
  selectedCurrencyId.value = params.selectedCurrencyId

  if (platform.value === 'desktop') {
    goTo('externalWalletConnect')
  } else {
    isExternalWalletDrawerOpened.value = true
  }
}

function goToBuyCrypto() {
  goTo('buyCrypto')
}
</script>

<style scoped>
.deposit {
  display: flex;
  flex-direction: column;
  max-height: 800px;
}

.mobile {
  &.deposit {
    width: 100%;
    height: 100%;
    min-height: auto;
    max-height: 100%;
    padding-top: 0;
  }
}
</style>
