<template>
  <div class="deposit-methods" :class="platform" data-t="deposit-methods">
    <div class="header">
      <div class="title" data-t="deposit-methods-title">
        {{ t('payments.deposit.depositVariants') }}
        <StCounter
          :label="currencies.length"
          bg-color="ghost"
          color="white"
          class="counter"
        />
      </div>
    </div>
    <div class="currencies" data-t="curencies-2o5t">
      <template v-for="currency in currencies" :key="currency.id">
        <div
          v-if="currency.type !== 'fiat'"
          class="currency-card"
          :class="{ disabled: !currency.allowedForDeposit }"
          data-t="currency-card"
          @click="handleCurrencyClick(currency)"
        >
          <div class="currency-title">
            <StIcon
              :name="currency.icon"
              size="50"
              class="currency-icon-mask"
            />
            <StIcon :name="currency.icon" size="28" class="currency-icon" />
            <div v-if="!currency.allowedForDeposit" class="lock-icon">
              <StIcon name="lock-solid" size="12" />
            </div>
            <div class="code-wrapper">
              <div
                :data-t="`currency-card-qfpz0-${currency.code}`"
                class="code"
              >
                {{ currency.code }}
              </div>
              <div class="name" data-t="currency-name">{{ currency.name }}</div>
            </div>
          </div>
        </div>
      </template>
      <SiteAvailableCrypto background="primary" class="site-crypto" />
    </div>
    <PaymentSystems
      v-if="isFiatPaymentsSystemsEnabled"
      @go-to-buy-crypto="emit('goToBuyCrypto')"
    />
  </div>
</template>

<script setup lang="ts">
import type { Currency } from '../../interfaces'
import PaymentSystems from '../PaymentSystems/PaymentSystems.vue'
import { useFiatPaymentSystemsEnabled } from '../../composables/useFiatPaymentSystemsEnabled'

const emit = defineEmits<{
  handleCurrencySelected: [id: number]
  goToBuyCrypto: []
}>()

const { platform } = usePlatform()

const { t } = useI18n()

const { isFiatPaymentsSystemsEnabled } = useFiatPaymentSystemsEnabled()

function handleCurrencyClick(currency: Currency) {
  if (currency.allowedForDeposit) {
    emit('handleCurrencySelected', currency.id)
  }
}

const { currencies } = useAllowedAssets()

const bottomGap = computed(() =>
  isFiatPaymentsSystemsEnabled.value ? 'var(--spacing-300)' : '300px',
)
</script>

<style scoped>
.deposit-methods {
  overflow: auto;
  display: flex;
  flex-direction: column;

  max-height: 700px;
  padding: 0 var(--spacing-300) var(--spacing-300);
}

.header {
  .title {
    display: flex;
    gap: var(--spacing-075);
    align-items: center;

    margin: 0 0 var(--spacing-150);

    font: var(--desktop-text-2lg-semibold);
  }

  .counter {
    width: 20px;
    height: 20px;
  }
}

.currency-card {
  cursor: pointer;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 112px;
  padding: var(--spacing-150) var(--spacing-150) var(--spacing-125);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);

  &:hover {
    background-color: var(--background-secondary);
  }

  &:first-child {
    grid-column: 1 / 3;
  }

  &.disabled {
    opacity: var(--disable-layer-opasity, 0.4);
  }
}

.currency-icon-mask {
  position: absolute;
  top: -14px;
  left: -14px;

  filter: blur(60px);

  mask-image: none;

  /* stylelint-disable */
  -webkit-backdrop-filter: blur(60px);
  /* stylelint-enable */
}

.currency-title {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-250);
}

.lock-icon {
  position: absolute;
  top: 16px;
  left: 16px;

  width: 16px;
  height: 16px;

  line-height: 0;
  text-align: center;

  background: var(--background-tertiary);
  border: var(--border-width-boldest) solid var(--background-tertiary);
  border-radius: var(--border-radius-full, 999px);
}

.code-wrapper {
  display: flex;
  flex-direction: column;
}

.code {
  font: var(--desktop-text-md-semibold);
}

.name {
  font: var(--desktop-text-xs-medium);
  color: var(--palette-light-600);
}

.site-crypto {
  grid-column: 1 / span 4;
  padding: 0;
}

.currencies {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-100);
  padding-bottom: var(--spacing-300);
}

.desktop {
  &.deposit-methods {
    padding-bottom: v-bind(bottomGap);
  }
}

.mobile {
  &.deposit-methods {
    overflow: hidden;
    overflow-y: auto;

    height: auto;
    max-height: 100%;
    padding: 0 var(--spacing-200) var(--spacing-200);
  }

  .header {
    margin: 0;
  }

  .title {
    margin: var(--spacing-200) 0 var(--spacing-150);
    font: var(--mobile-headline-semibold);
  }

  .currencies {
    grid-template-columns: repeat(3, 1fr);
    min-height: auto;
    padding-bottom: var(--spacing-400);
  }

  .currency-card {
    height: 103px;
  }

  .code-wrapper {
    gap: var(--spacing-025);
  }

  /* stylelint-disable */
  .code {
    font: var(--mobile-text-semibold);
  }

  .name {
    font: var(--mobile-caption-1-regular);
  }

  /* stylelint-enable */

  .value {
    font: var(--mobile-caption-1-medium);

    span {
      font: var(--mobile-text-semibold);
    }
  }

  .site-crypto {
    grid-column: 1 / span 3;
    padding: 0;
  }
}
</style>
