import { useUserStore } from '@st/user/stores/useUserStore'

export function usePreferredCurrencyCode() {
  const userStore = useUserStore()
  const { userSettings } = storeToRefs(userStore)
  const { updateUserSettings } = userStore

  const preferredCurrencyCode = computed({
    get: () =>
      // @ts-expect-error поменять когда будет готов бэкенд
      userSettings.value?.preferredCurrencyCodeForCurrencyExchange || 'USD',
    set: (value: string) => {
      updateUserSettings([
        {
          // @ts-expect-error поменять когда будет готов бэкенд
          name: 'preferredCurrencyCodeForCurrencyExchange',
          value: value.toUpperCase(),
        },
      ])
    },
  })

  return {
    preferredCurrencyCode,
  }
}
