<template>
  <form
    class="ton-connect"
    :class="platform"
    data-t="ton-connect"
    @submit.prevent="handleSubmit"
  >
    <div v-if="platform === 'desktop'" class="go-back">
      <StButton
        :label="t('payments.deposit.goBack')"
        size="l"
        type="text-only"
        icon="chevron-left"
        is-left-icon
        @click="emit('back')"
      />
    </div>
    <div class="content">
      <h2 v-if="platform === 'desktop'">
        {{ t('payments.tonConnect.depositFromWallet') }}
      </h2>
      <div class="wallet">
        <img
          src="./assets/wallet.png"
          alt="wallet"
          :width="platform === 'desktop' ? '40' : '32'"
          :height="platform === 'desktop' ? '40' : '32'"
        />
        <div class="address">
          <div class="label">{{ t('payments.tonConnect.wallet') }}</div>
          <div clas="value">
            {{ truncateAddress(walletAddress) }}
          </div>
        </div>
        <StButton
          :label="t('payments.tonConnect.disable')"
          :size="platform === 'desktop' ? 'l' : 'm'"
          type="gray"
          @click="disconnectWallet"
        />
      </div>
      <StInput
        v-bind="amount.componentBindings"
        :label="t('payments.tonConnect.sum')"
        :caption-top-first="balance ? t('payments.tonConnect.balance') : ''"
        :caption-top-second="balance"
        :size="platform === 'desktop' ? 'l' : 'm'"
        inputmode="decimal"
        :placeholder="
          t('payments.tonConnect.placeholder', { min: format(limit.min) })
        "
        @update:model-value="onDecimalInputUpdate"
      >
        <template #postfix>
          <div class="input-postfix">{{ selectedCurrency }}</div>
          <div class="postfix-button">
            <StButton
              label="MAX"
              type="ghost"
              :size="platform === 'desktop' ? 's' : 'xs'"
              @click="handleMaxAmount"
            />
          </div>
        </template>
      </StInput>
      <div class="button-wrapper">
        <StButton submit :label="t('payments.tonConnect.deposit')" />
        <div class="disclaimer">
          {{ t('payments.tonConnect.disclaimer', { address: depositAddress }) }}
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { isValidNumber } from '@st/utils'
import Decimal from '@st/decimal'
import { required, useForm } from '@st/validate'
import { useExternalWalletConnect } from '../../composables/useExternalWalletConnect'
import { truncateAddress } from '../../helpers'
import { useTariffsStore } from '../../stores/useTariffsStore'
import type {
  SendTonConnectTransactionParams,
  ExternalConnectType,
} from '../../types'
import { useCurrenciesStore } from '../../stores/useCurrenciesStore'
import { useTonConnectTransaction } from './helpers/useTonConnectTransaction'
import { useWalletConnectTransaction } from './helpers/useWalletConnectTransaction'

const props = withDefaults(
  defineProps<{
    depositAddress: string
    selectedCurrencyId?: number
    type?: ExternalConnectType
  }>(),
  {
    type: 'walletConnect',
  },
)

const emit = defineEmits<{
  back: []
}>()

const { t } = useI18n()
const { platform } = usePlatform()
const { walletAddress, disconnect } = useExternalWalletConnect(props.type)

const balance = ref('')

const { findLimit } = useTariffsStore()

const { getCurrencyById } = useCurrenciesStore()
const selectedCurrency = computed(() => {
  if (!props.selectedCurrencyId) return ''
  const currency = getCurrencyById(props.selectedCurrencyId)

  return currency.code || ''
})

const limit = computed(() => {
  const foundLimit = findLimit({
    currencyId: 34,
    networkId: 34,
    operationType: 'deposit',
  })

  return {
    min: foundLimit?.minAmount ?? '0',
    max: foundLimit?.maxAmount,
  }
})

const { format } = useCryptoFormatter()

const {
  fields: { amount },
  validate,
  isValid,
  values,
} = useForm({
  fieldsSchema: {
    amount: {
      initialValue: '',
      validators: [
        {
          rule: required,
          errorMessage: t('payments.tonConnect.amountIsRequired'),
        },
        {
          rule: isValidNumber,
          errorMessage: t('payments.tonConnect.amountIsInvalid'),
        },
        {
          rule: (value) =>
            isValidNumber(value) &&
            (!limit.value.min ||
              new Decimal(value).greaterThanOrEqualTo(limit.value.min)),
          errorMessage: () =>
            t('payments.tonConnect.amountIsBelowMinLimit', {
              min: format(limit.value.min),
            }),
        },
        {
          rule: (value) =>
            isValidNumber(value) &&
            (!limit.value.max ||
              new Decimal(value).lessThanOrEqualTo(limit.value.max)),
          errorMessage: () =>
            t('payments.tonConnect.amountIsAboveMaxLimit', {
              max: format(limit.value.max ?? '0'),
            }),
        },
      ],
    },
  },
})

const { sendWalletConnectTransaction, getWalletConnectBalance } =
  useWalletConnectTransaction()
const { sendTonConnectTransaction } = useTonConnectTransaction()
const { open } = useToast()

function handleSubmit() {
  validate()
  if (!isValid.value) return
  const transactionAmount = new Decimal(values.value.amount)
    .mul(1000000000)
    .toString()

  const transaction: SendTonConnectTransactionParams['transaction'] = {
    validUntil: Math.floor(Date.now() / 1000) + 180,
    messages: [
      {
        address: props.depositAddress,
        amount: transactionAmount,
      },
    ],
  }

  if (props.type === 'tonConnect') {
    sendTonConnectTransaction({ transaction })
  }

  if (props.type === 'walletConnect') {
    try {
      sendWalletConnectTransaction({
        address: props.depositAddress,
        amount: transactionAmount,
      })
    } catch (e) {
      open({
        label: t('payments.tonConnect.somethingWentWrong'),
        type: 'negative',
      })
    }
  }
}

async function disconnectWallet() {
  await disconnect()
  emit('back')
}

function onDecimalInputUpdate() {
  amount.value = amount.value.replace(',', '.')
}

function handleMaxAmount() {
  amount.value = balance.value
}

const { format: amountFormatter } = useNumberFormatter()

onMounted(async () => {
  if (props.type !== 'walletConnect') return
  const walletConnectBalance = await getWalletConnectBalance(
    props.depositAddress as `0x${string}`,
  )

  balance.value = amountFormatter(String(walletConnectBalance.value))
})
</script>

<style scoped>
.ton-connect {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-height: 748px;
  padding: var(--spacing-300);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-300);
}

h2 {
  margin: 0;
  padding: 0;
  font: var(--desktop-text-2xl-semibold);
}

.wallet {
  display: flex;
  gap: var(--spacing-200, 16px);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-150, 12px) var(--spacing-200, 16px);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);
  box-shadow: 0 0 80px -8px #1d1d2a;

  .address {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .label {
    font: var(--desktop-text-sm-medium);
    color: var(--text-tertiary);
  }

  .value {
    font: var(--desktop-text-md-medium);
    color: var(--text-primary);
  }

  img {
    border-radius: var(--border-radius-200);
  }
}

.input-postfix {
  padding-right: var(--spacing-150);
  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);
}

.postfix-button {
  padding-left: var(--spacing-150);
  border-left: 1px solid var(--border-primary);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-150);
  justify-content: flex-end;

  width: 100%;
  height: 100%;
}

.disclaimer {
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
  text-align: center;
}

.ton-connect.mobile {
  min-height: auto;
  padding: var(--spacing-200);

  .content {
    padding: 0;
  }

  .wallet {
    padding: var(--spacing-100) var(--spacing-150);
    border-radius: var(--border-radius-125);
  }

  .disclaimer {
    font: var(--mobile-caption-1-regular);
  }

  .input-postfix {
    font: var(--desktop-text-sm-medium);
  }
}
</style>
