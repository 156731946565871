import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import {
  type AppKitNetwork,
  mainnet,
  polygon,
  base,
  sepolia,
} from '@reown/appkit/networks'
import { http, createConfig, WagmiPlugin } from '@wagmi/vue'
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query'

export function useWalletConnectInit() {
  const isInitialized = !!inject('wagmiConfig')
  if (isInitialized) return

  const { vueApp } = useNuxtApp()

  const projectId = useRuntimeConfig().public.walletConnectProjectId

  if (!projectId) {
    console.log('PROJECT_ID is not set')
  }

  const networks: [AppKitNetwork, ...AppKitNetwork[]] = [mainnet, polygon, base]

  const wagmiAdapterInstance = new WagmiAdapter({
    networks,
    projectId,
  })

  vueApp.provide('walletConnectProjectId', projectId)
  vueApp.provide('walletConnectNetworks', networks)
  vueApp.provide('wagmiAdapter', wagmiAdapterInstance)

  const queryClient = new QueryClient()
  const config = createConfig({
    chains: [mainnet, sepolia],
    transports: {
      [mainnet.id]: http(),
      [sepolia.id]: http(),
    },
  })

  vueApp.provide('wagmiConfig', config)

  vueApp.use(WagmiPlugin, { config }).use(VueQueryPlugin, { queryClient })
}
