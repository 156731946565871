import type { AppKitNetwork } from '@reown/appkit/networks'
import {
  type ChainAdapter,
  useAppKit,
  useAppKitAccount,
  createAppKit,
  useDisconnect,
} from '@reown/appkit/vue'
import type { UseExternalConnectReturn } from '../types'

export function useWalletConnect(): UseExternalConnectReturn {
  const wagmiAdapterInstance = inject('wagmiAdapter') as ChainAdapter
  const networks = inject('walletConnectNetworks') as [
    AppKitNetwork,
    ...AppKitNetwork[],
  ]
  const projectId = inject('walletConnectProjectId') as string

  createAppKit({
    adapters: [wagmiAdapterInstance],
    networks,
    projectId,
    themeMode: 'dark',
    features: {
      analytics: true,
      send: true,
    },
    metadata: {
      name: 'AppKit Vue Example',
      description: 'AppKit Vue Example',
      url: 'https://reown.com/appkit',
      icons: ['https://avatars.githubusercontent.com/u/179229932?s=200&v=4'],
    },
    themeVariables: {
      '--w3m-accent': '#000000',
      // @ts-expect-error idk
      '--w3m-z-index': '1000000',
    },
  })

  const accountInfo = useAppKitAccount()
  const { open: openModal } = useAppKit()
  const { disconnect: disconnectWalletConnect } = useDisconnect()

  const walletAddress = computed(() => accountInfo.value.address || '')
  const isConnected = computed(() => accountInfo.value.isConnected)

  function disconnect(): Promise<void> {
    return disconnectWalletConnect()
  }

  return {
    openModal,
    isConnected,
    walletAddress,
    disconnect,
  }
}
