<template>
  <div class="amount-wrapper">
    <InputWithSelect
      v-model:select="fiatCurrencyId"
      v-model:input="fiatAmountModel"
      :error="fiatAmountErrorModel"
      :input-placeholder="'0'"
      :platform="platform"
      :select-items="fiatCurrenciesList"
      :title="t('payments.buyCrypto.inputTitle1')"
      :is-disabled-input="isDisabled"
      is-disabled-select
      data-t="buy-crypto-00wc"
      @handle-focus="handleFiatAmountFocus"
    />
    <InputWithSelect
      v-model:select="currencyId"
      v-model:input="cryptoAmountModel"
      :error="cryptoAmountErrorModel"
      :input-placeholder="'0'"
      :platform="platform"
      :is-disabled-input="isDisabled"
      :select-items="cryptoCurrenciesList"
      :title="t('payments.buyCrypto.inputTitle2')"
      :subtitle="convertRateText"
      is-disabled-select
      class="amount-input-select"
      @handle-focus="handleAmountFocus"
    />
    <!-- <FastAmounts
            :currency-id="currencyId"
            :network-id="networkId"
            :selected-currency-code="selectedCurrency.code"
            @handle-amount-click="handleAmountClick"
          /> -->
  </div>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { isValidNumber } from '@st/utils'
import { useExchangeDataStore } from '@st/payments/stores/useExchangeDataStore'

const props = defineProps<{
  cryptoAmount: string
  fiatAmount: string
  cryptoAmountError: string
  fiatAmountError: string
  isDisabled: boolean
}>()

const emit = defineEmits<{
  (e: 'update:cryptoAmount', value: string): void
  (e: 'update:fiatAmount', value: string): void
  (e: 'update:cryptoAmountError', value: string): void
  (e: 'update:fiatAmountError', value: string): void
}>()

const cryptoAmountModel = computed({
  get() {
    return props.cryptoAmount
  },
  set(value: string) {
    emit('update:cryptoAmount', value.trim())
  },
})

const fiatAmountModel = computed({
  get() {
    return props.fiatAmount
  },
  set(value: string) {
    emit('update:fiatAmount', value.trim())
  },
})

const fiatAmountErrorModel = computed({
  get() {
    return props.fiatAmountError
  },
  set(value: string) {
    emit('update:fiatAmountError', value)
  },
})

const cryptoAmountErrorModel = computed({
  get() {
    return props.cryptoAmountError
  },
  set(value: string) {
    emit('update:cryptoAmountError', value)
  },
})

const { platform } = usePlatform()
const { t } = useI18n()

const dataStore = useExchangeDataStore()

const {
  fiatCurrenciesList,
  fiatCurrencyId,
  selectedMethod,
  cryptoCurrenciesList,
  currencyId,
  selectedCurrency,
  convertRate,
  selectedFiatCurrency,
  selectedProvider,
} = storeToRefs(dataStore)

const { format: formatCrypto } = useCryptoFormatter({
  currency: computed(() => selectedCurrency.value?.code),
})

const currentInputFocus = ref<'crypto' | 'fiat'>()

function handleAmountFocus() {
  currentInputFocus.value = 'crypto'
}

function handleFiatAmountFocus() {
  currentInputFocus.value = 'fiat'
}

function calculateAmount() {
  if (!isValidNumber(fiatAmountModel.value)) {
    fiatAmountErrorModel.value = t('payments.withdrawal.validationError')
    return
  }

  cryptoAmountModel.value = new Decimal(fiatAmountModel.value.trim())
    .div(convertRate.value)
    .toDecimalPlaces(8)
    .toString()
}

function calculateFiatAmount() {
  if (!isValidNumber(cryptoAmountModel.value)) {
    cryptoAmountErrorModel.value = t('payments.withdrawal.validationError')
    return
  }

  fiatAmountModel.value = new Decimal(cryptoAmountModel.value.trim())
    .mul(convertRate.value)
    .toDecimalPlaces(2)
    .toString()
}

watch(cryptoAmountModel, (newValue) => {
  cryptoAmountErrorModel.value = ''
  fiatAmountErrorModel.value = ''

  if (!convertRate.value) return

  if (!newValue) {
    fiatAmountModel.value = ''

    return
  }

  if (currentInputFocus.value === 'crypto') {
    calculateFiatAmount()
  }
})

watch(fiatAmountModel, (newValue) => {
  cryptoAmountErrorModel.value = ''
  fiatAmountErrorModel.value = ''

  if (!convertRate.value) return

  if (!newValue) {
    cryptoAmountModel.value = ''

    return
  }

  if (currentInputFocus.value === 'fiat') {
    calculateAmount()
  }
})

const convertRateText = computed(() => {
  if (!convertRate.value) return ''

  const rate = new Decimal(1)
    .mul(convertRate.value)
    .toDecimalPlaces(2)
    .toString()

  return `${formatCrypto(1)} ≈ ${rate} ${
    selectedFiatCurrency.value?.title ?? ''
  }`
})

watch(fiatCurrencyId, () => {
  cryptoAmountModel.value = ''
})

watch(selectedMethod, (_, prevValue) => {
  if (!prevValue || (!cryptoAmountModel.value && !fiatAmountModel.value)) return

  calculateAmount()
})

watch(selectedProvider, (_, prevValue) => {
  if (!prevValue || (!cryptoAmountModel.value && !fiatAmountModel.value)) return

  calculateFiatAmount()
})

onMounted(async () => {
  await until(selectedProvider.value)

  calculateFiatAmount()
})
</script>

<style scoped>
.amount-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
}
</style>
