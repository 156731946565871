import BANK_TRANSFER from '../assets/methods/Bank_transfer.png'
import PIX from '../assets/methods/Pix.png'
import GCASH from '../assets/methods/GCash.png'
import MAYA from '../assets/methods/Maya.png'
import CARDS from '../assets/methods/Bank_card.png'
import LOCAL_EFTS from '../assets/methods/Local_EFTS.png'
import WEBPAY from '../assets/methods/WebPay.png'
import HITES from '../assets/methods/Hites.png'
import CHEK from '../assets/methods/Chek.png'
import MACH from '../assets/methods/Mach.png'
import TODITO from '../assets/methods/Todito.png'
import MOBILE_MONEY from '../assets/methods/Mobile_money.png'
import PROMPT_PAY from '../assets/methods/PromtPay.png'
import TRUE_MONEY from '../assets/methods/TrueMoney.png'
import MPAY from '../assets/methods/MPAY.png'
import ECOM from '../assets/methods/ECOM.png'
import P2P from '../assets/methods/P2P.png'
import M10 from '../assets/methods/M10.png'
import POLI from '../assets/methods/POLI.png'
import TOUCH_NGO from '../assets/methods/Touch_nGo.png'
import GRAB_PAY from '../assets/methods/Grab_Pay.png'
import BOOST from '../assets/methods/Boost.png'
import SHOPEE_PAY from '../assets/methods/Shopee_Pay.png'
import FPX from '../assets/methods/FPX.png'
import DUITNOW from '../assets/methods/Duit_Now.png'
import BIOPAGO from '../assets/methods/Biopago.png'
import PAGO_MOVIL from '../assets/methods/Pago_Movil.png'
import QRIS from '../assets/methods/QRIS.png'
import VIRTUAL_ACCOUNT from '../assets/methods/Virtual_account.png'
import MasterCard from '../assets/methods/MasterCard.png'
import Visa from '../assets/methods/Visa.png'
import ApplePay from '../assets/methods/ApplePay.png'
import GooglePay from '../assets/methods/GooglePay.png'
import ONLINE_BANKING from '../assets/methods/Online_banking.png'

const imagesMap: Record<string, string> = {
  BANK_TRANSFER,
  PIX,
  GCASH,
  MAYA,
  CARDS,
  LOCAL_EFTS,
  WEBPAY,
  HITES,
  CHEK,
  MACH,
  TODITO,
  MOBILE_MONEY,
  PROMPT_PAY,
  TRUE_MONEY,
  MPAY,
  ECOM,
  P2P,
  M10,
  POLI,
  TOUCH_NGO,
  GRAB_PAY,
  BOOST,
  SHOPEE_PAY,
  FPX,
  DUITNOW,
  BIOPAGO,
  PAGO_MOVIL,
  PAGO_MOVIL_INST: PAGO_MOVIL,
  QRIS,
  VIRTUAL_ACCOUNT,
  ONLINE_BANKING,
  pix_instant_payment: PIX,
  MasterCard,
  Visa,
  ApplePay,
  GooglePay,
}

export function getMethodImage(name: string) {
  return imagesMap[name]
}
