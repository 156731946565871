import { sendTransaction, getBalance, type Config } from '@wagmi/core'
import { parseGwei } from 'viem'
import type { SendWalletConnectTransactionParams } from '../../../types'

export function useWalletConnectTransaction() {
  const config = inject('wagmiConfig') as Config

  function sendWalletConnectTransaction(
    params: SendWalletConnectTransactionParams,
  ) {
    return sendTransaction(config, {
      to: params.address as `0x${string}`,
      value: parseGwei(params.amount),
    })
  }

  function getWalletConnectBalance(depositAddress: `0x${string}`) {
    return getBalance(config, {
      address: depositAddress,
    })
  }

  return {
    sendWalletConnectTransaction,
    getWalletConnectBalance,
  }
}
