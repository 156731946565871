<template>
  <div class="deposit-form" :class="platform" data-t="deposit-form">
    <StDrawerHeader
      v-if="isMobile"
      with-back-button
      with-border-bottom
      :title="t('payments.deposit.makeDeposit')"
      @back="emit('back')"
    />
    <div v-else class="top-controls">
      <div class="go-back">
        <StButton
          :label="t('payments.deposit.goBack')"
          size="l"
          type="text-only"
          icon="chevron-left"
          is-left-icon
          @click="emit('back')"
        />
      </div>
      <StTooltip trigger="hover">
        <template #activator>
          <div class="top-control-action">
            <StIcon name="book-solid" size="16" />
            <div class="text">
              {{ t('payments.deposit.howToReplenish') }}
            </div>
          </div>
        </template>
        <div class="tooltip-content">
          <DepositTooltip />
        </div>
      </StTooltip>
    </div>
    <div class="content">
      <h2 v-if="isDesktop" class="title">
        {{ t('payments.deposit.makeDeposit') }}
      </h2>
      <DepositBonusBanner
        v-if="favoriteNewDepositBonus"
        :bonus="favoriteNewDepositBonus"
        :selected-currency-code="selectedCurrency?.code"
      />
      <div class="content-wrapper">
        <CurrencySelect
          v-model="currencyId"
          :platform="platform"
          operation-type="deposit"
        />
        <NetworkSelect
          v-model="networkId"
          :networks="networks"
          :platform="platform"
          operation-type="deposit"
          :is-disabled="networks.length === 1"
        />
        <div class="address">
          <DepositAddress
            :loading="status === 'pending' || !!error"
            :selected-currency="selectedCurrency"
            :selected-network="selectedNetwork"
            :address="address"
            :platform="platform"
            @handle-connect="handleConnect"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// TODO remove after useDeps payments
import DepositBonusBanner from '@st/bonuses/components/DepositBonusBanner/DepositBonusBanner.vue'
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'

import DepositAddress from '../DepositAddress/DepositAddress.vue'
import DepositTooltip from './parts/DepositTooltip.vue'
import { useCurrenciesStore } from '../../stores/useCurrenciesStore'
import type { ExternalConnectType } from '../../types'

const currenciesStore = useCurrenciesStore()
const { currencies } = storeToRefs(currenciesStore)
const { open } = useToast()
const { platform, isDesktop, isMobile } = usePlatform()

const emit = defineEmits<{
  back: []
  handleConnect: [
    { address: string; type: ExternalConnectType; selectedCurrencyId: number },
  ]
}>()

const { selectedCurrencyId } = defineProps<{
  selectedCurrencyId: number
}>()

const { t } = useI18n()

const currencyId = ref(selectedCurrencyId)
const selectedCurrency = computed(() => currencies.value[currencyId.value])

const networkId = ref()
const { networks } = useAllowedAssets({ selectedCurrencyId: currencyId })

watch(
  currencyId,
  () => {
    if (networks.value.length === 1) {
      networkId.value = networks.value[0].id
    } else {
      networkId.value = ''
    }
  },
  {
    immediate: true,
  },
)

const selectedNetwork = computed(() =>
  networks.value.find((network) => network.id === networkId.value),
)

const { data, execute, status, error } = useStFetch('/deposit/address/get', {
  method: 'POST',
  immediate: false,
  watch: false,
  body: computed(() => ({
    currencyId: currencyId.value,
    networkId: networkId.value,
  })),
})

watch(
  networkId,
  (newValue) => {
    if (!newValue) return

    execute()
  },
  {
    immediate: true,
  },
)

function getErrorMessage(code: string): string {
  switch (code) {
    case 'LAST_USER_VERIFICATION_IS_REJECTED':
      return t('payments.deposit.refillForbidden')
    case 'ACTIVE_USER_VERIFICATION_ALREADY_EXISTS':
      return t('payments.kycVerification.verificationRequired')
    default:
      return t('payments.buyCrypto.somethingWentWrong')
  }
}

watch(
  () => error.value,
  (err) => {
    if (!err) return
    const errorBody = err.data
    if (!errorBody) return

    const { error: errorCode } = errorBody
    open({
      label: getErrorMessage(errorCode),
      type: 'negative',
    })
  },
)

const address = computed(() => data.value?.address)

const { favoriteNewDepositBonus } = storeToRefs(useDepositBonusesStore())

function handleConnect(type: ExternalConnectType) {
  const selectedId = selectedCurrency.value.id

  emit('handleConnect', {
    address: address.value as string,
    type,
    selectedCurrencyId: selectedId,
  })
}
</script>

<style scoped>
.deposit-form {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);

  height: 748px;
  padding: var(--spacing-300);
}

.top-controls {
  display: flex;
  gap: var(--spacing-300);
  align-items: center;
  justify-content: space-between;

  .go-back {
    flex-grow: 1;
  }
}

.title {
  margin: 0;
  font: var(--desktop-text-2xl-semibold);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.top-control-action {
  cursor: pointer;

  position: relative;

  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  color: var(--text-tertiary);

  .text {
    font: var(--desktop-text-sm-semibold);
  }

  &:hover {
    color: var(--palette-light-1000);
  }
}

.address {
  margin-top: var(--spacing-100);
}

.mobile {
  &.deposit-form {
    overflow: hidden;
    overflow-y: auto;
    gap: 0;
    padding: 0;
  }

  .content {
    gap: var(--spacing-200);
    padding: var(--spacing-200);
  }

  .address {
    margin-top: var(--spacing-050);
  }

  .go-back {
    line-height: 0;
    border: none;
  }
}
</style>
