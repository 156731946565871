import { useTelegram } from '@st/telegram/composables/useTelegram'

export function useRedirect(): {
  redirect: (url: Promise<string | null>) => Promise<void>
} {
  const { openTelegramLink, isTelegramMiniApp } = useTelegram()

  async function redirect(url: Promise<string | null>) {
    if (isTelegramMiniApp.value) {
      const resolvedUrl = await Promise.resolve(url)
      if (!resolvedUrl) return
      openTelegramLink(resolvedUrl)
      return
    }

    const page = window.open('about:blank', '_blank')
    if (!page) {
      const resolvedUrl = await Promise.resolve(url)
      if (!resolvedUrl) return
      window.location.href = resolvedUrl
      return
    }

    const resolvedUrl = await Promise.resolve(url)
    if (!resolvedUrl) {
      page.close()
      return
    }
    page.location.href = resolvedUrl
  }

  return { redirect }
}
