import { useSettingsStore } from '@st/core/stores/useSettingsStore'

export function useWalletConnectEnabledNetworks(): ComputedRef<
  Record<string, number[]>
> {
  const { settings } = storeToRefs(useSettingsStore())
  const currencySettings = settings.value?.currencySettings ?? {}

  return computed(() => {
    const result: Record<string, number[]> = {}

    Object.keys(currencySettings).forEach((currencyId) => {
      result[currencyId] =
        currencySettings[currencyId]?.walletConnectAllowedNetworkIds ?? []
    })

    return result
  })
}
