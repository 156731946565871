import { useTonConnect } from './useTonConnect'
import { useWalletConnect } from './useWalletConnect'
import type { ExternalConnectType, UseExternalConnectReturn } from '../types'

export function useExternalWalletConnect(
  type: ExternalConnectType,
): UseExternalConnectReturn {
  if (type === 'tonConnect') {
    return useTonConnect()
  }

  return useWalletConnect()
}
