import { useStFetch } from '@st/api/composables/useStFetch'
import { sortBy } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import { iconsMap } from '../components/BuyCrypto/parts/iconsMap'
import type {
  FiatCurrency,
  ExchangeMethod,
  ExchangePaymentProvider,
} from '../interfaces'

interface CalypsoParams {
  currencyId: Ref<number>
  networkId: Ref<number>
}

interface CalypsoReturn {
  fiatCurrenciesList: ComputedRef<FiatCurrency[]>
  fetchExchangeMethods: (arg: FiatCurrency) => Promise<ExchangeMethod[]>
  isFiatCurrenciesLoading: ComputedRef<boolean>
  fetchCurrencyList: () => Promise<void>
}

export function useCalypsoInit({
  currencyId,
  networkId,
}: CalypsoParams): CalypsoReturn {
  const stFetch = useRawStFetch()

  const {
    data: calypsoGeoData,
    status,
    execute,
  } = useStFetch('/deposit/calypso/geo', {
    method: 'post',
    body: computed(() => ({
      currencyId: currencyId.value,
      networkId: networkId.value,
    })),
    immediate: false,
  })

  const isFiatCurrenciesLoading = computed(() => status.value === 'pending')

  const calypsoMethodId = computed(() => calypsoGeoData.value?.methodId ?? '')

  const geoList = computed(() => {
    if (!calypsoGeoData.value?.geo) return []

    return sortBy(calypsoGeoData.value?.geo, (item) => [item.geoCode])
  })

  const fiatCurrenciesList = computed<FiatCurrency[]>(
    () =>
      geoList.value?.map((item) => ({
        id: item.geoId,
        title: String(item.geoCode),
        icon: iconsMap[item.geoCode] ?? ('' as IconName),
        providers: ['calypso'],
      })),
  )

  async function fetchExchangeMethods(currency: FiatCurrency) {
    if (!currency.providers.includes('calypso')) return []

    const { data } = await stFetch('/deposit/calypso/rate', {
      method: 'post',
      body: {
        currencyId: currencyId.value,
        geoId: currency?.id as string,
        methodId: calypsoMethodId.value,
      },
    })

    return (
      data.method?.map((method) => ({
        ...method,
        providers: [
          {
            provider: 'calypso' as ExchangePaymentProvider,
            fixedRate: method.fixedRate,
            // @ts-expect-error api
            minLimit: method.minLimit,
            // @ts-expect-error api
            maxLimit: method.maxLimit,
          },
        ],
      })) ?? []
    )
  }

  return {
    fiatCurrenciesList,
    fetchExchangeMethods,
    isFiatCurrenciesLoading,
    fetchCurrencyList: execute,
  }
}
