<template>
  <div class="withdrawal" :class="[platform, current]" data-t="withdrawal">
    <NavigationTabs v-if="current === 'form'" :type="platform" />
    <div
      v-else-if="isVisibleBackButton && platform !== 'mobile'"
      class="go-back-button"
    >
      <StButton
        :label="t('payments.withdrawal.goBack')"
        size="l"
        type="text-only"
        icon="chevron-left"
        is-left-icon
        @click="goTo('form')"
      />
    </div>
    <div v-if="current !== 'twoFactor'" class="withdrawal-contents">
      <WithdrawalForm
        v-if="current === 'form'"
        :selected-currency-id="
          newAddress?.currencyId || withdrawalData?.selectedCurrencyId
        "
        :selected-network-id="
          newAddress?.networkId || withdrawalData?.selectedNetworkId
        "
        :selected-address-name="withdrawalData?.address"
        :initial-amount="withdrawalData?.amount"
        :platform="platform"
        @add-new-address="goToAddNewAddress"
        @handle-next-step="goToConfirmation"
      />
      <AddNewAddress
        v-if="current === 'add'"
        :selected-currency-id="withdrawalData?.selectedCurrencyId"
        :selected-network-id="withdrawalData?.selectedNetworkId"
        class="new-address"
        :platform="platform"
        @handle-next-step="addNewAddress"
        @current-step-changed="handleNewAdressStep"
      />
      <WithdrawalConfirmation
        v-if="current === 'confirm' && withdrawalData"
        :data="withdrawalData"
        :platform="platform"
        @back="goTo('form')"
        @handle-next-step="handleConfirmationNextStep"
      />
    </div>
    <div
      v-if="current === 'twoFactor' && withdrawalData && isTwoFactorEnabled"
      class="two-factor-wrapper"
    >
      <TwoFactorAuth
        :title="t('payments.withdrawal.confirmationOfPayment')"
        :error="createWithdrawalError"
        :platform="platform"
        @back="goTo('form')"
        @code-entered="handleCodeEntered"
        @retry="resendCode"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { WithdrawalData } from '../../interfaces'
import { useWithdrawalStore } from '../../stores/useWithdrawalStore'
import { useWithdrawal } from './composables/useWithdrawal'
import WithdrawalConfirmation from './parts/WithdrawalConfirmation.vue'
import WithdrawalForm from './parts/WithdrawalForm.vue'
import TwoFactorAuth from '../TwoFactorAuth/TwoFactorAuth.vue'

withDefaults(
  defineProps<{
    platform?: 'desktop' | 'mobile'
  }>(),
  {
    platform: 'desktop',
  },
)

const { t } = useI18n()
const router = useRouter()
const { goTo, current } = useStepper(['form', 'add', 'confirm', 'twoFactor'])
const {
  checkValidationError,
  sendCodeExecute,
  sendCodeRefresh,
  sendCodeStatus,
  withdrawalExecute,
  createWithdrawalError,
  confirmationCode,
  createWithdrawalStatus,
} = useWithdrawal()

const withdrawalStore = useWithdrawalStore()
const { setWithdrawalData, clearWithdrawalData } = withdrawalStore
const { withdrawalData } = storeToRefs(withdrawalStore)

const isTwoFactorEnabled = ref(true)
const isVisibleBackButton = ref(true)

function handleNewAdressStep(step: string) {
  if (step === 'second') {
    isVisibleBackButton.value = false
  } else {
    isVisibleBackButton.value = true
  }
}

function goToConfirmation(data: WithdrawalData) {
  setWithdrawalData(data)
  goTo('confirm')
}

if (!String(router.options.history?.state?.back).match('withdrawalStatus')) {
  clearWithdrawalData()
}

async function createWithdrawal(code?: string) {
  if (!withdrawalData.value) return

  confirmationCode.value = code
  await withdrawalExecute()
}

function goToWithdrawalInfoPopup(status: 'success' | 'error') {
  router.replace({
    query: {
      modal: 'withdrawalStatus',
      amount: withdrawalData.value?.amount,
      currency: withdrawalData.value?.currencyCode,
      commission: withdrawalData.value?.commission,
      status,
    },
  })
}

watch(createWithdrawalStatus, () => {
  if (createWithdrawalStatus.value === 'pending') return

  if (createWithdrawalStatus.value !== 'error') {
    goToWithdrawalInfoPopup('success')
  }

  if (createWithdrawalStatus.value === 'error') {
    if (!createWithdrawalError.value) return
    const err = createWithdrawalError.value.data?.error

    if (!checkValidationError(err as string)) {
      goToWithdrawalInfoPopup('error')
    }
  }
})

async function handleConfirmationNextStep() {
  if (!withdrawalData.value) return

  if (
    withdrawalData.value.twoFactorAuthRequired ||
    withdrawalData.value.isNewAddress
  ) {
    await sendCodeExecute()

    if (sendCodeStatus.value === 'success') {
      goTo('twoFactor')
    }

    if (sendCodeStatus.value === 'error') {
      goToWithdrawalInfoPopup('error')
    }
  } else {
    await createWithdrawal()
  }
}

async function handleCodeEntered(value: string) {
  await createWithdrawal(value)
}

const newAddress = ref()

function addNewAddress(value: any) {
  newAddress.value = value
  goTo('form')
}

function resendCode() {
  sendCodeRefresh()
}

function goToAddNewAddress(data: WithdrawalData) {
  setWithdrawalData(data)

  goTo('add')
}

const withdrawalHeight = computed(() =>
  ['twoFactor', 'confirm'].includes(current.value) ? '564px' : 'auto',
)
</script>

<style scoped>
.withdrawal {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: v-bind(withdrawalHeight);

  &.desktop {
    scrollbar-width: none;
    overflow: auto;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.form {
      min-height: min(calc(100vh - 64px), 748px);
    }
  }
}

.go-back-button {
  padding: var(--spacing-300) var(--spacing-300) var(--spacing-050);
}

.withdrawal-contents {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 var(--spacing-300) var(--spacing-300);
}

.new-address {
  margin-top: var(--spacing-050);
}

.two-factor-wrapper {
  height: 514px;
  padding: 0 var(--spacing-300);
}

.withdrawal.mobile {
  width: 100%;
  padding-top: 0;

  .withdrawal-contents {
    overflow-y: hidden;
    padding: 0;
  }

  .add-new-address-wrapper {
    padding: var(--spacing-200);
  }

  .two-factor-wrapper {
    padding: 0;
  }
}
</style>
