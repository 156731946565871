<template>
  <div :class="platform" class="deposit-bonus" data-t="deposit-bonus-banner">
    <div class="content">
      <div class="left-side">
        <div class="bonus-title-wrapper">
          <h4 class="bonus-title" :title="bannerTitle">{{ bannerTitle }}</h4>
          <img
            v-if="isDesktop"
            class="tooltip"
            src="./assets/tooltip.svg"
            @click="openTooltip"
          />
        </div>
        <DepositBonusAwards
          size="m"
          direction="column"
          :awards="bonusesOutput"
          :is-step-bonus="isStepBonus"
        />
      </div>
      <StDivider orientation="v" :spacing="200" />
      <div class="right-side">
        <div class="min-deposit-title">{{ t('bonuses.depositMinShort') }}</div>
        <div class="min-deposit-amount">
          <div class="deposit-crypto">
            {{ cryptoMinAmount }}
            <StCryptoIcon
              :currency="selectedCurrencyCode"
              :size="isMobile ? 14 : 20"
            />
          </div>
          <span class="fiat-amount">
            {{ `(${minDeposit})` }}
          </span>
        </div>
        <div class="bonus-expiration">
          <span v-if="isDesktop">
            {{ t('bonuses.bonusExpires') }}
          </span>
          <BonusTimer
            v-if="bonus.expiredAt"
            size="s"
            is-shown-days-label
            :is-mobile="isMobile"
            :expires-at="bonus.expiredAt"
          />
        </div>
        <div v-if="isMobile" class="mobile-tooltip">
          <img
            class="tooltip"
            src="./assets/tooltip.svg"
            @click="openTooltip"
          />
        </div>
        <img class="chest" src="./assets/chest.png" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BonusTimer from '../FreeBonusCard/parts/BonusTimer.vue'
import type { ExtendedDepositBonus } from '../../types'
import { useDepositBonus } from '../../composables/useDepositBonus'
import DepositBonusAwards from '../DepositBonusAwards/DepositBonusAwards.vue'
import { useBonusesDeps } from '../../useDeps'

interface Props {
  bonus: ExtendedDepositBonus
  selectedCurrencyCode: string
}

const { bonus, selectedCurrencyCode } = defineProps<Props>()

const { t } = useI18n()

const { platform, isDesktop, isMobile } = usePlatform()

const { appCurrencyCode, convert } = useBonusesDeps()

const { format: formatCrypto } = useCryptoFormatter()

const {
  minDepositAmount: minDeposit,
  titleType,
  bonusesOutput,
  isStepBonus,
} = useDepositBonus(toRef(() => bonus))

const cryptoMinAmount = computed(() => {
  const minFromAmount =
    bonus.minFromAmount ?? bonus.minDepositAmountInAppCurrency ?? 0
  return formatCrypto(
    convert(minFromAmount, {
      from: appCurrencyCode.value,
      to: selectedCurrencyCode,
    }),
  )
})

const bannerTitle = computed(() =>
  t('bonuses.depositNTitle', { type: titleType.value }),
)

const router = useRouter()
function openTooltip() {
  router.push({
    query: {
      modal: 'depositBonusTooltip',
      id: bonus.id,
    },
  })
}
</script>

<style scoped>
.deposit-bonus {
  display: flex;
  min-height: 104px;
  background: linear-gradient(95deg, #721528 0%, #3d0b15 100%);
  border-radius: var(--border-radius-150);
}

.content {
  display: flex;
  width: 100%;
  padding: var(--spacing-150) var(--spacing-200) var(--spacing-150)
    var(--spacing-200);
}

.left-side {
  width: 190px;
}

.tooltip {
  cursor: pointer;
}

.right-side {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bonus-title-wrapper {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  font: var(--desktop-text-md-semibold);
}

.bonus-title {
  overflow: hidden;

  max-width: 175px;
  margin: 0;

  font: var(--desktop-text-sm-semibold);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.min-deposit-title {
  width: fit-content;
  font: var(--desktop-text-xs-medium);
}

.min-deposit-amount {
  display: flex;
  gap: var(--spacing-025);
  align-items: baseline;
  font: var(--desktop-text-xl-semibold);

  span {
    display: flex;
    gap: var(--spacing-050);
    align-items: center;

    font: var(--desktop-text-md-medium);
    line-height: 0;
    color: var(--text-secondary);
  }
}

.deposit-crypto {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
}

.bonus-expiration {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  width: fit-content;
  margin-top: auto;
  padding: var(--spacing-050) var(--spacing-075) var(--spacing-050)
    var(--spacing-100);

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary, rgb(255 255 255 / 64%));

  background: rgb(255 255 255 / 8%);
  border-radius: var(--border-radius-075, 6px);
}

.chest {
  position: absolute;
  right: -36px;
  bottom: -36px;

  width: 108px;
  height: 108px;
}

.fiat-amount {
  margin-left: var(--spacing-050);
}

.mobile {
  &.deposit-bonus {
    width: auto;
    min-height: 84px;
  }

  .content {
    padding: var(--spacing-125) var(--spacing-125) var(--spacing-125)
      var(--spacing-150);
  }

  .left-side {
    width: 120px;
    padding-right: var(--spacing-150);
  }

  .right-side {
    padding-left: var(--spacing-150);
  }

  .bonus-title {
    font: var(--mobile-caption-1-medium);
  }

  .min-deposit-title {
    font: var(--mobile-caption-1-regular);
  }

  .min-deposit-amount {
    font: var(--mobile-headline-semibold);

    span {
      font: var(--mobile-caption-1-regular);
    }
  }

  .chest {
    right: -22px;
    bottom: -26px;
    width: 64px;
    height: 64px;
  }

  .mobile-tooltip {
    position: absolute;
    top: 0;
    right: 0;
  }

  .bonus-expiration {
    padding: var(--spacing-025) var(--spacing-075);
    font: var(--desktop-text-xs-medium);
  }

  .sub-bonus-text {
    overflow: hidden;
    max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
