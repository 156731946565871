import type { ExchangeMethod, ExchangePaymentProvider } from '../interfaces'
import { useCurrenciesStore } from './useCurrenciesStore'
import { useAccountsStore } from './useAccountsStore'
import { useInitExchangeData } from '../composables/useInitExchangeData'
import { usePreferredCurrencyCode } from '../composables/usePreferredCurrencyCode'

export const useExchangeDataStore = defineStore('exchangeDataStore', () => {
  const CURRENCY_ID = 1
  const NETWORK_ID = 4
  const currenciesStore = useCurrenciesStore()
  const accountsStore = useAccountsStore()
  const { platform } = usePlatform()
  const { realAccounts } = storeToRefs(accountsStore)
  const { appCurrency } = storeToRefs(currenciesStore)

  const { allowedCurrencyIds } = useCalypso()
  const currencyId = ref(CURRENCY_ID)
  const networkId = ref(NETWORK_ID)

  const { preferredCurrencyCode } = usePreferredCurrencyCode()

  const selectedProvider = ref<ExchangePaymentProvider | undefined>()
  function setSelectedProvider(provider: ExchangePaymentProvider) {
    selectedProvider.value = provider
  }

  const fiatCurrencyId = ref()
  const {
    fetchExchangeMethods,
    fiatCurrenciesList,
    isFiatCurrenciesLoading,
    fetchCurrencyList,
  } = useInitExchangeData({
    currencyId,
    networkId,
  })

  fetchCurrencyList()

  watch(fiatCurrenciesList, (newValue) => {
    if (!newValue.length) return

    const foundCurrency = newValue.find(
      (currency) => currency.title === preferredCurrencyCode.value,
    )

    if (foundCurrency) {
      fiatCurrencyId.value = foundCurrency.id
      preferredCurrencyCode.value = foundCurrency.title
    } else {
      const firstCurrency = newValue[0]
      preferredCurrencyCode.value = firstCurrency.title
      fiatCurrencyId.value = firstCurrency?.id ?? ''
    }
  })

  const selectedCurrency = computed(() =>
    currenciesStore.getCurrencyById(CURRENCY_ID),
  )

  const selectedFiatCurrency = computed(() =>
    fiatCurrenciesList.value.find(
      (currency) => currency.id === fiatCurrencyId.value,
    ),
  )

  const exchangeMethods = ref<ExchangeMethod[]>()
  const selectedMethod = ref<ExchangeMethod>()

  const isLoadingExchangeMethods = ref(false)
  watch(selectedFiatCurrency, async (newSelectedFiatCurrency) => {
    if (!newSelectedFiatCurrency) return

    isLoadingExchangeMethods.value = true
    exchangeMethods.value = await fetchExchangeMethods(newSelectedFiatCurrency)
    isLoadingExchangeMethods.value = false
  })

  watch(exchangeMethods, (newValue) => {
    if (!newValue) return

    const { 0: firstElement } = newValue
    selectedMethod.value = firstElement
  })

  function setSelectedMethod(method: ExchangeMethod) {
    selectedMethod.value = method
  }

  const { format: formatCurrency } = useCurrencyFormatter({
    currency: appCurrency.value.code,
  })

  const { format: formatCrypto } = useCryptoFormatter({
    currency: computed(() => selectedCurrency.value.code),
  })

  const cryptoCurrenciesList = computed(() =>
    realAccounts.value
      .filter((account) =>
        allowedCurrencyIds.value.includes(account.currencyId),
      )
      .map((account) => ({
        id: account.currencyId,
        title: account.code,
        value:
          platform.value === 'mobile'
            ? formatCrypto(account.balance)
            : undefined,
        subValue:
          platform.value === 'mobile'
            ? `~${formatCurrency(account.fiatBalance)}`
            : undefined,
        icon: account.icon,
      })),
  )

  const convertRate = computed(() => {
    if (!selectedProvider.value) return 1

    const foundProvider = selectedMethod.value?.providers.find(
      (provider) => provider.provider === selectedProvider.value,
    )

    return foundProvider?.fixedRate ?? 1
  })

  return {
    fiatCurrenciesList,
    fiatCurrencyId,
    exchangeMethods,
    selectedMethod,
    cryptoCurrenciesList,
    currencyId,
    networkId,
    selectedCurrency,
    convertRate,
    selectedFiatCurrency,
    setSelectedMethod,
    setSelectedProvider,
    selectedProvider,
    isLoadingExchangeMethods,
    isFiatCurrenciesLoading,
  }
})
