<template>
  <div class="deposit-address" :class="platform" data-t="deposit-address">
    <div class="address-wrapper">
      <div class="title">{{ t('payments.deposit.depositAddress') }}</div>
      <div class="content-wrapper">
        <div class="content">
          <StSkeletonLoader
            v-if="isLoading"
            type="rectangle"
            :width="platform === 'desktop' ? '108px' : '68px'"
            :height="platform === 'desktop' ? '108px' : '68px'"
            :shimmer-opacity="0.32"
          />
          <div v-else class="qr-code" data-t="qr-code-46yz">
            <canvas ref="canvas" class="canvas"></canvas>
          </div>
          <div class="address">
            <StSkeletonLoader
              v-if="isLoading"
              type="rectangle"
              :width="platform === 'desktop' ? '390px' : '196px'"
              height="50px"
              :shimmer-opacity="0.32"
            />
            <template v-else>
              <div class="description">
                {{ t('payments.deposit.scanCode') }}
              </div>
              <div class="wallet-address" data-t="wallet-address">
                {{ address }}
              </div>
            </template>
            <div v-if="platform === 'desktop'" class="copy-block">
              <div class="copy">
                <StButton
                  v-if="address"
                  :label="t('payments.deposit.copy')"
                  size="s"
                  type="gray"
                  :icon="isCopied ? 'check' : 'copy-solid'"
                  is-left-icon
                  class="copy-button"
                  data-t="copy-button-6ado"
                  @click="copy"
                >
                </StButton>
              </div>
            </div>
          </div>
          <StButton
            v-if="platform === 'mobile' && address"
            size="xl"
            type="text-tertiary"
            is-left-icon
            data-t="copy-button-1a7o"
            :icon="isCopied ? 'check' : 'copy-solid'"
            class="copy-button"
            @click="copy"
          />
        </div>
        <TonConnectButton
          v-if="isTonConnectVisible"
          @handle-connect="emit('handleConnect', 'tonConnect')"
        />
        <WalletConnectButton
          v-if="isWalletConnectVisible"
          @handle-connect="emit('handleConnect', 'walletConnect')"
        />
      </div>
      <Hints
        v-if="selectedCurrency && selectedNetwork"
        :selected-currency="selectedCurrency"
        :selected-network="selectedNetwork"
      />
    </div>
    <div v-if="!selectedNetwork" class="overlay-text" :class="platform">
      <div class="select-network" data-t="select-network-cvzk">
        <div class="icon">
          <StIcon name="arrow-right" size="48" />
        </div>
        <div class="text">{{ t('payments.deposit.overlayText') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import QRCode from 'qrcode'
import type { Currency, Network } from '../../interfaces'
import TonConnectButton from './parts/TonConnectButton.vue'
import Hints from './parts/Hints.vue'
import { useTonConnectEnabled } from '../../composables/useTonConnectEnabled'
import { useWalletConnectEnabledNetworks } from '../../composables/useWalletConnectEnabledNetworks'
import WalletConnectButton from './parts/WalletConnectButton.vue'
import type { ExternalConnectType } from '../../types'

interface Props {
  loading?: boolean
  selectedCurrency: Currency
  selectedNetwork?: Network
  address?: string
  platform: 'desktop' | 'mobile'
}

const {
  loading,
  selectedCurrency,
  selectedNetwork,
  address = '',
  platform = 'desktop',
} = defineProps<Props>()

const emit = defineEmits<{
  handleConnect: [type: ExternalConnectType]
}>()

const { t } = useI18n()
const canvas = ref<HTMLCanvasElement | null>(null)

const canvasCreated = ref(false)

watchEffect(() => {
  if (!address) {
    return
  }

  QRCode.toCanvas(canvas.value, address, {
    margin: 3,
    width: platform === 'desktop' ? 108 : 68,
  }).then(() => {
    canvasCreated.value = true
  })
})

const { copy, isCopied } = useCopyWithToast(
  computed(() => address),
  t('payments.deposit.copied'),
)

const isLoading = computed(() => loading || (address && !canvasCreated.value))

const isTonConnectEnabled = useTonConnectEnabled()
const isTonConnectVisible = computed(() => {
  if (!isTonConnectEnabled.value || !address) return false

  const isTonCurrencyOrNetwork =
    (selectedCurrency.id === 1 && selectedNetwork?.id === 34) ||
    (selectedCurrency.id === 34 && selectedNetwork?.id === 34)

  return isTonCurrencyOrNetwork
})

const walletConnectEnabledNetworks = useWalletConnectEnabledNetworks()
const isWalletConnectVisible = computed(() => {
  if (
    !walletConnectEnabledNetworks.value[selectedCurrency.id] ||
    !selectedNetwork?.id ||
    !address
  )
    return false

  return walletConnectEnabledNetworks.value[selectedCurrency.id].includes(
    selectedNetwork?.id,
  )
})
</script>

<style scoped>
.deposit-address {
  position: relative;
  max-width: 548px;
}

.address-wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  font: var(--desktop-text-lg-semibold);
}

.content-wrapper {
  display: flex;
  flex-direction: column;

  margin-top: var(--spacing-100);
  padding: var(--spacing-200);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.content {
  display: flex;
  gap: var(--spacing-200);
  align-items: center;
}

.qr-code {
  width: 108px;
  height: 108px;
  background-color: var(--palette-light-1000);
  border-radius: var(--border-radius-100);

  .canvas {
    border-radius: var(--border-radius-100);
  }
}

.address {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
  justify-content: space-between;

  min-height: 108px;

  .description {
    font: var(--desktop-text-xs-medium);
    color: var(--palette-light-600);
  }

  .wallet-address {
    max-width: 392px;
    font: var(--desktop-text-md-semibold);
    word-wrap: break-word;
  }

  .copy-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

.overlay-text {
  pointer-events: none;

  position: absolute;
  top: -16px;
  bottom: -16px;

  background: rgb(17 17 24 / 80%);
  backdrop-filter: blur(10px);

  .text {
    max-width: 400px;
    font: var(--desktop-text-xl-semibold);
    text-align: center;
  }

  .icon {
    transform: rotate(270deg);

    margin-bottom: var(--spacing-125);
    padding: var(--spacing-150);

    color: var(--icon-disable);

    animation: down-up 0.75s infinite alternate;
  }

  &.mobile {
    right: -16px;
    left: -16px;
  }

  &.desktop {
    right: -16px;
    left: -16px;
  }
}

.select-network {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 0 var(--spacing-200);
}

.copy-button {
  &:hover {
    :deep(.button-icon) {
      background-color: var(--palette-light-1000) !important;
    }
  }
}

@keyframes down-up {
  0% {
    transform: rotate(270deg) translateX(0);
  }

  100% {
    transform: rotate(270deg) translateX(16px);
  }
}

.deposit-address.mobile {
  .title {
    font: var(--mobile-headline-semibold);
  }

  .content-wrapper {
    margin: var(--spacing-100) 0;
  }

  .description {
    font: var(--mobile-caption-1-regular);
  }

  .content {
    gap: var(--spacing-100);
    padding: var(--spacing-100);
    background-color: var(--background-base);
    border-radius: var(--border-radius-100);
  }

  .copy-button {
    padding: 0;
  }

  .address {
    justify-content: center;
    min-height: auto;
  }

  .wallet-address {
    max-width: none;
    font: var(--mobile-text-content-regular);
    overflow-wrap: anywhere;
  }

  .qr-code {
    width: 68px;
    height: 68px;
  }

  .overlay-text {
    .text {
      font: var(--mobile-headline-semibold);
    }
  }
}
</style>
