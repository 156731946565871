<template>
  <div
    class="withdrawal-confirmation"
    :class="platform"
    data-t="withdrawal-confirmation"
  >
    <StDrawerHeader
      v-if="isMobile"
      with-back-button
      full-width
      :title="t('payments.withdrawal.withdrawalConfirmation')"
      @close="goBack"
      @back="emit('back')"
    />
    <h2 v-else>
      {{ t('payments.withdrawal.withdrawalConfirmation') }}
    </h2>
    <div class="information">
      <div class="row">
        <div class="title">{{ t('payments.withdrawal.network') }}</div>
        <div class="value" data-t="network">
          <span>
            {{ data.network?.description }}
          </span>
          {{ data.network?.name }}
        </div>
      </div>
      <div class="row">
        <div class="title">{{ t('payments.withdrawal.walletAddress') }}</div>
        <div class="value" data-t="address">
          {{ data.address }}
        </div>
      </div>
      <div class="row">
        <div class="title">{{ t('payments.withdrawal.withdrawalAmount') }}</div>
        <div class="value" data-t="amount">
          {{ format(data.amount || '') }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          {{ t('payments.withdrawal.withdrawalCommission') }}
        </div>
        <div class="value" data-t="fee">
          <template v-if="Number(data.commission)">
            {{ format(data.commission || '') }}
          </template>
          <template v-else>
            {{ t('payments.withdrawal.noCommission') }}
          </template>
        </div>
      </div>
      <div class="row row-last">
        <div class="title">{{ t('payments.withdrawal.finalAmount') }}</div>
        <div class="value" data-t="final-amount">
          {{ format(data.finalAmount || '') }}
        </div>
      </div>
    </div>
    <div class="alert">
      <div>
        <StIcon name="circle-alert-solid" class="icon" size="20" />
      </div>
      <div class="text">
        {{ t('payments.withdrawal.confirmationAlert') }}
      </div>
    </div>
    <div class="confirmation-button-wrapper">
      <StButton
        class="confirmation-button"
        :label="t('payments.withdrawal.confirm')"
        :loading="isCreateInvoicePenging"
        :size="platform === 'desktop' ? 'xl' : 'l'"
        @click="handleConfirm"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { WithdrawalData } from '../../../interfaces'

const { t } = useI18n()
const { goBack } = useRouterBack()

const emit = defineEmits<{
  (e: 'handleNextStep'): void
  (e: 'back'): void
}>()

const props = defineProps<{
  data: WithdrawalData
}>()

const { platform, isMobile } = usePlatform()
const { format } = useCryptoFormatter({ currency: props.data.currencyCode })

const isCreateInvoicePenging = ref(false)

async function handleConfirm() {
  if (!props.data.amount) return

  isCreateInvoicePenging.value = true
  emit('handleNextStep')

  isCreateInvoicePenging.value = false
}
</script>

<style scoped>
.withdrawal-confirmation {
  display: flex;
  flex-direction: column;

  h2 {
    margin: 0;
    padding: 0;
    font: var(--desktop-text-2xl-semibold);
  }
}

.information {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  justify-content: space-between;

  margin-top: var(--spacing-300);
  padding: var(--spacing-200);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.row {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;

  .title {
    font: var(--desktop-text-md-medium);
    color: var(--palette-light-600);
  }

  .value {
    display: flex;
    gap: var(--spacing-100);

    max-width: 360px;

    font: var(--desktop-text-md-semibold);
    text-align: right;
    word-break: break-all;

    span {
      display: flex;
      align-items: center;

      padding: var(--spacing-025) var(--spacing-075);

      font: var(--desktop-text-xs-semibold);
      color: var(--palette-light-600);

      background-color: var(--background-secondary);
      border-radius: var(--border-radius-full);
    }
  }
}

.row-last {
  padding-top: var(--spacing-200);
  border-top: 1px solid var(--border-secondary);
}

.alert {
  display: flex;

  max-width: 548px;
  margin: var(--spacing-050) 0 var(--spacing-300);
  padding: var(--spacing-200);

  font: var(--desktop-text-sm-medium);
  color: var(--palette-light-600);
  text-align: left;

  background-color: rgb(255 216 107 / 8%);
  border-radius: var(--border-radius-150);

  .icon {
    color: var(--accent-gold-primary);
  }

  .text {
    margin-left: var(--spacing-150);
  }
}

.confirmation-button {
  width: 100%;
}

.withdrawal-confirmation.mobile {
  height: 100%;

  .information {
    gap: var(--spacing-150);
    margin: var(--spacing-100) var(--spacing-200) 0;
  }

  .row {
    .title {
      font: var(--mobile-text-regular);
    }

    .value {
      font: var(--mobile-text-regular);

      span {
        padding: var(--spacing-025) var(--spacing-050);
        font: var(--mobile-caption-2-medium);
      }
    }
  }

  .confirmation-button-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;

    margin: 0 var(--spacing-200);
    padding-bottom: var(--spacing-200);
  }

  .alert {
    margin: var(--spacing-100) var(--spacing-200);

    .text {
      font: var(--mobile-text-content-regular);
    }
  }
}
</style>
