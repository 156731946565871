import { TonConnectUI } from '@tonconnect/ui'
import type { SendTonConnectTransactionParams } from '../../../types'

export function useTonConnectTransaction() {
  const tonConnect: TonConnectUI = inject('tonConnect') as TonConnectUI

  function sendTonConnectTransaction(params: SendTonConnectTransactionParams) {
    tonConnect.sendTransaction(params.transaction, params.options)
  }

  return { sendTonConnectTransaction }
}
