<template>
  <div class="payment-systems" :class="platform" data-t="payment-systems">
    <h2 data-t="title">
      {{ t('payments.deposit.paymentSystems') }}
      <StCounter
        :label="exchangeMethods?.length"
        bg-color="ghost"
        color="white"
        class="counter"
      />
    </h2>
    <RegionSelect v-model="preferredCurrencyCode" />
    <template v-if="isFiatCurrenciesLoading || isLoadingExchangeMethods">
      <div class="payment-system-wrapper">
        <div v-for="(_, idx) in 4" :key="idx" class="payment-system">
          <StSkeletonLoader
            width="28px"
            height="28px"
            type="circle"
          ></StSkeletonLoader>
          <StSkeletonLoader width="82px" height="12px"></StSkeletonLoader>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="payment-system-wrapper" data-t="payment-system-wrapper">
        <div
          v-for="method in exchangeMethods"
          :key="method.methodName"
          class="payment-system"
          data-t="payment-system"
          @click="handleExchangeMethod(method.fixedRateId)"
        >
          <div v-if="getMethodImage(method.methodCode)">
            <img
              :src="getMethodImage(method.methodCode)"
              class="method-image-mask"
            />
            <img
              :src="getMethodImage(method.methodCode)"
              class="method-image"
              data-t="method-image"
            />
          </div>
          <div class="name" data-t="method-code">
            {{ method.methodName }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useExchangeDataStore } from '../../stores/useExchangeDataStore'
import { getMethodImage } from './helpers/getMethodImage'
import RegionSelect from './parts/RegionSelect.vue'
import { usePreferredCurrencyCode } from '../../composables/usePreferredCurrencyCode'

const emit = defineEmits<{
  goToBuyCrypto: []
}>()

const { t } = useI18n()

const exchangeDataStore = useExchangeDataStore()
const { setSelectedMethod } = exchangeDataStore
const {
  exchangeMethods,
  fiatCurrenciesList,
  fiatCurrencyId,
  isLoadingExchangeMethods,
  isFiatCurrenciesLoading,
} = storeToRefs(exchangeDataStore)

function handleExchangeMethod(id: string) {
  const foundMethod = exchangeMethods.value?.find(
    (method) => method.fixedRateId === id,
  )

  if (!foundMethod) return
  setSelectedMethod(foundMethod)

  emit('goToBuyCrypto')
}

const { platform } = usePlatform()
const { preferredCurrencyCode } = usePreferredCurrencyCode()

const foundCurrency = computed(() =>
  fiatCurrenciesList.value.find(
    (currency) => currency.title === preferredCurrencyCode.value,
  ),
)

watch(foundCurrency, (newValue) => {
  if (!newValue) return

  fiatCurrencyId.value = newValue.id
})
</script>

<style scoped>
.payment-systems {
  display: flex;
  flex-direction: column;
}

h2 {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  margin: 0 0 var(--spacing-150);

  font: var(--desktop-text-2lg-semibold);
}

.counter {
  width: 20px;
  height: 20px;
}

.payment-system {
  cursor: pointer;

  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  justify-content: space-between;

  height: 112px;
  padding: var(--spacing-150);

  background: var(--background-primary, #1f1f29);
  border-radius: var(--border-radius-150, 12px);

  &:hover {
    background-color: var(--background-secondary);
  }
}

.payment-system-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-100);
}

.method-image-mask {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);

  width: 70px;
  height: 60px;

  filter: blur(50px);

  /* stylelint-disable */
  -webkit-backdrop-filter: blur(60px);
  /* stylelint-enable */
}

.method-image {
  transform: translateZ(0);
  height: 32px;
}

.name {
  font: var(--mobile-text-semibold);
}

.mobile {
  .payment-system-wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-100);
  }

  h2 {
    font: var(--mobile-headline-semibold);
  }

  .payment-system {
    min-width: auto;
    height: 104px;
  }
}
</style>
