<template>
  <div class="region-select">
    <StSkeletonLoader v-if="isFiatCurrenciesLoading" />
    <StBaseSelect
      v-else
      v-model="selectedCurrency"
      :mobile-title="t('payments.deposit.enterCurrencyCode')"
      dropdown-max-height="200"
      top-offset="-8"
      full-screen
    >
      <template #field>
        <RegionSelect
          :full-name="selectedCurrencyItem?.title"
          :icon="selectedCurrencyItem?.icon"
          @toggle="toggleRegionSelect"
        />
      </template>
      <template #top>
        <div
          class="dropdown-search-wrapper"
          :class="platform"
          data-t="dropdown-search-wrapper"
        >
          <StInput
            v-model="searchValue"
            :placeholder="t('payments.deposit.enterCurrencyCode')"
            icon-prefix="search"
            size="s"
            class="search-input"
            :autofocus="isDesktop"
          >
            <template v-if="searchValue" #postfix>
              <StIcon
                name="cross-large"
                size="16"
                class="close-icon"
                data-t="clear-icon"
                @click="clearSearch"
              />
            </template>
          </StInput>
        </div>
      </template>
      <div class="options">
        <div v-if="!filteredCountryList.length && searchValue" class="no-items">
          <StIcon name="search-none" size="48" class="search-icon" />
          <div class="disclaimer">
            <div class="title">
              {{ t('payments.deposit.noItemsSearchTitle') }}
            </div>
            <div class="subtitle">
              {{ t('payments.deposit.noItemsSearchSubtitle') }}
            </div>
          </div>
        </div>
        <template v-else>
          <PaymentsSelectOption
            v-for="item in filteredCountryList"
            :key="item.id"
            :item="item"
            :platform="platform"
            :is-selected="selectedCurrency === item.title"
          />
        </template>
      </div>
    </StBaseSelect>
  </div>
</template>

<script setup lang="ts">
import { useExchangeDataStore } from '../../../stores/useExchangeDataStore'
import RegionSelect from './RegionSelectField.vue'

const selectedCurrency = defineModel<string>()

const { t } = useI18n()
const { platform, isDesktop } = usePlatform()

const exchangeDataStore = useExchangeDataStore()

const { isFiatCurrenciesLoading, fiatCurrenciesList } =
  storeToRefs(exchangeDataStore)

const searchValue = ref('')

function clearSearch() {
  searchValue.value = ''
}

const currenciesList = computed(() =>
  fiatCurrenciesList.value.map((currency) => ({
    id: currency.title,
    title: currency.title,
    fullName: currency.title,
    icon: currency.icon,
  })),
)

const selectedCurrencyItem = computed(() =>
  currenciesList.value.find(
    (currency) => currency.title === selectedCurrency.value,
  ),
)

const filteredCountryList = computed(() =>
  currenciesList.value.filter((currency) =>
    currency.title.toLowerCase().includes(searchValue.value.toLowerCase()),
  ),
)

function toggleRegionSelect() {
  searchValue.value = ''
}
</script>

<style scoped>
.region-select {
  width: 100%;
  margin-bottom: var(--spacing-200);

  :deep(.base-dropdown) {
    background-color: var(--background-primary);
  }
}

.close-icon {
  cursor: pointer;
  color: var(--icon-tertiary);
}

.dropdown-search-wrapper {
  padding: var(--spacing-100) var(--spacing-150);
  background-color: var(--background-primary);
}

.no-items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;

  height: 100%;

  /* stylelint-disable */
  padding: 49px 0;
  /* stylelint-enable */
  text-align: center;

  .search-icon {
    color: var(--text-tertiary);
  }

  .title {
    font: var(--desktop-text-sm-semibold);
  }

  .subtitle {
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }
}

.dropdown-search-wrapper.mobile {
  background-color: transparent;
  border: none;
}
</style>
