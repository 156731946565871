<template>
  <div class="second-step" :class="platform">
    <StDrawerHeader
      v-if="platform === 'mobile'"
      :title="t('payments.buyCrypto.completePayment')"
      with-border-bottom
    />
    <div class="top-section">
      <div class="image-wrapper">
        <img src="../assets/second-step.svg" />
        <div class="spinner-wrapper">
          <StSpinner size="32" />
        </div>
        <div class="provider-image">
          <img
            v-if="selectedProvider === 'moonpay'"
            src="../../ExchangeStatus/assets/moonpay.svg"
            height="30"
            width="30"
          />
          <img
            v-if="selectedProvider === 'calypso'"
            src="../../ExchangeStatus/assets/calypso.svg"
            height="30"
            width="30"
          />
        </div>
      </div>
      <div class="text">
        {{ t('payments.deposit.completePayment', { provider: providerName }) }}
      </div>
    </div>

    <div class="bottom-section">
      <StButton
        :label="
          t('payments.buyCrypto.openPaymentProvider', {
            provider: providerName,
          })
        "
        size="2xl"
        icon="arrow-up-right"
        class="exchange-button"
        @click="emit('handleRedirect')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useExchangeDataStore } from '@st/payments/stores/useExchangeDataStore'
import StDrawerHeader from '@st/ui/components/StDrawer/StDrawerHeader.vue'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import type { Props as ExchangeStatusModalProps } from '../../ExchangeStatus/ExchangeStatus.vue'

interface DepositStatusSocketData {
  depositId: number
  depositStatusFrom: string
  depositStatus: 'success' | 'error' | string
  amount: number
  currencyId: number
  provider: 'calypso' | 'moonpay'
}

const emit = defineEmits<{
  handleRedirect: []
}>()

const dataStore = useExchangeDataStore()
const { selectedProvider } = storeToRefs(dataStore)

const { platform } = usePlatform()
const { t } = useI18n()
const { getCurrencyById } = useCurrenciesStore()

const providerName = computed(() =>
  selectedProvider.value === 'moonpay' ? 'MoonPay' : 'Calypso',
)

const router = useRouter()
const io = useSocket()

function handleSocketPaymentData(data: DepositStatusSocketData) {
  const { depositStatus: status, amount, currencyId, provider } = data
  if (status !== 'processed' && status !== 'rejected') return
  router.push({
    query: {
      modal: 'exchangeStatus',
      provider,
      status,
      amount,
      currency: getCurrencyById(currencyId)
        .code as ExchangeStatusModalProps['currency'],
    },
  })
}

onMounted(() => io.on('depositStatus', handleSocketPaymentData))
onBeforeUnmount(() => io.off('depositStatus', handleSocketPaymentData))
</script>

<style scoped>
.second-step {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  min-height: 600px;
}

.top-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;
  justify-content: center;
}

.image-wrapper {
  position: relative;
}

.spinner-wrapper {
  position: absolute;
  top: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.provider-image {
  position: absolute;
  top: 148px;
  right: 98px;
}

.text {
  max-width: 440px;
  font: var(--desktop-text-2lg-semibold);
  text-align: center;
}

.bottom-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;

  width: 100%;
  height: 100%;
  margin-top: var(--spacing-400);

  span {
    font: var(--desktop-text-sm-medium);
    color: var(--text-secondary);
  }

  .exchange-button {
    min-width: 240px;
  }
}

.second-step.mobile {
  justify-content: flex-start;
  height: 100%;
  min-height: auto;
  margin-top: 0;

  .top-section {
    justify-content: unset;
    margin-top: var(--spacing-800);
    padding: var(--spacing-500) 0 0;
  }

  .text {
    width: 340px;
    font: var(--mobile-headline-semibold);
  }

  .bottom-section {
    gap: var(--spacing-150);
    height: auto;
    margin-top: var(--spacing-500);

    span {
      font: var(--mobile-caption-1-regular);
    }
  }

  .title {
    font: var(--mobile-title-2-semibold);
  }

  .exchange-button {
    min-width: 220px;
  }
}
</style>
