import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useStFetch } from '@st/api/composables/useStFetch'
import { useRawStFetch } from '@st/api/composables/useRawStFetch'
import type { IconName } from '@st/ui/components/StIcon/types'
import { iconsMap } from '../components/BuyCrypto/parts/iconsMap'
import type {
  FiatCurrency,
  ExchangePaymentProvider,
  ExchangeMethod,
} from '../interfaces'

interface UseMoonpayInitReturn {
  fiatCurrenciesList: ComputedRef<FiatCurrency[]>
  fetchExchangeMethods: (currency: FiatCurrency) => Promise<ExchangeMethod[]>
  isFiatCurrenciesLoading: ComputedRef<boolean>
  fetchCurrencyList: () => Promise<void>
}

export function useMoonpayInit(): UseMoonpayInitReturn {
  const stFetch = useRawStFetch()
  const settingsStore = useSettingsStore()
  const { settings } = storeToRefs(settingsStore)
  const moonpayTargetCurrency = settings.value?.frontMoonpayTargetCurrency

  const {
    data: geoData,
    status,
    execute,
  } = useStFetch('/deposit/moonpay/geo', {
    method: 'post',
    immediate: false,
  })

  const isFiatCurrenciesLoading = computed(() => status.value === 'pending')

  const geo = computed(() => geoData.value?.geo ?? [])

  const fiatCurrenciesList = computed<FiatCurrency[]>(() =>
    geo.value.map((item) => ({
      id: item.fiatCurrency.toLowerCase(),
      title: String(item.fiatCurrency),
      icon: iconsMap[item.fiatCurrency] ?? ('' as IconName),
      providers: ['moonpay'],
    })),
  )

  async function fetchExchangeMethods(currency: FiatCurrency) {
    if (!currency.providers.includes('moonpay')) return []

    const { data } = await stFetch('/deposit/moonpay/rate', {
      method: 'post',
      body: {
        targetCryptoCurrencyCode: moonpayTargetCurrency as string,
        baseFiatCurrencyCode: currency.title.toLowerCase(),
      },
    })

    const creditCardMethod = data.methods.find(
      (method) => method.methodName === 'credit_debit_card',
    )

    const pixMethod = data.methods.find(
      (method) => method.methodName === 'pix_instant_payment',
    )

    const creditCardMethods = creditCardMethod
      ? [
          {
            methodName: 'Visa',
            methodCode: 'Visa',
            fixedRateId: 'Visa',
            fixedRate: creditCardMethod.fixedRate,
            providers: [
              {
                provider: 'moonpay' as ExchangePaymentProvider,
                fixedRate: creditCardMethod.fixedRate,
                minLimit: creditCardMethod.minLimit,
                maxLimit: creditCardMethod.maxLimit,
              },
            ],
          },
          {
            methodName: 'MasterCard',
            methodCode: 'MasterCard',
            fixedRateId: 'MasterCard',
            fixedRate: creditCardMethod.fixedRate,
            providers: [
              {
                provider: 'moonpay' as ExchangePaymentProvider,
                fixedRate: creditCardMethod.fixedRate,
                minLimit: creditCardMethod.minLimit,
                maxLimit: creditCardMethod.maxLimit,
              },
            ],
          },
          {
            methodName: 'ApplePay',
            methodCode: 'ApplePay',
            fixedRateId: 'ApplePay',
            fixedRate: creditCardMethod.fixedRate,
            providers: [
              {
                provider: 'moonpay' as ExchangePaymentProvider,
                fixedRate: creditCardMethod.fixedRate,
                minLimit: creditCardMethod.minLimit,
                maxLimit: creditCardMethod.maxLimit,
              },
            ],
          },
          {
            methodName: 'GooglePay',
            methodCode: 'GooglePay',
            fixedRateId: 'GooglePay',
            fixedRate: creditCardMethod.fixedRate,
            providers: [
              {
                provider: 'moonpay' as ExchangePaymentProvider,
                fixedRate: creditCardMethod.fixedRate,
                minLimit: creditCardMethod.minLimit,
                maxLimit: creditCardMethod.maxLimit,
              },
            ],
          },
        ]
      : []

    return [
      ...creditCardMethods,
      ...(pixMethod
        ? [
            {
              methodName: 'Pix',
              methodCode: pixMethod.methodName,
              fixedRate: pixMethod.fixedRate,
              fixedRateId: pixMethod.methodName,
              providers: [
                {
                  provider: 'moonpay' as ExchangePaymentProvider,
                  fixedRate: pixMethod.fixedRate,
                  minLimit: pixMethod.minLimit,
                  maxLimit: pixMethod.maxLimit,
                },
              ],
            },
          ]
        : []),
    ]
  }

  return {
    fiatCurrenciesList,
    fetchExchangeMethods,
    isFiatCurrenciesLoading,
    fetchCurrencyList: execute,
  }
}
