<template>
  <div class="navigation-tabs" :class="platform" data-t="navigation-tabs">
    <div class="buy-wrapper">
      <StTabs
        :tabs="tabs"
        :model-value="selectedTabId"
        :type="platform === 'desktop' ? 'separate' : 'mobile-l'"
        @update:model-value="handleTabClicked"
      />
    </div>
    <button
      v-if="platform !== 'desktop'"
      class="button close"
      type="button"
      data-t="button-close-yo20"
      @click="close"
    >
      <StIcon name="cross-large" :size="20" />
    </button>
  </div>
</template>

<script setup lang="ts">
import StTabs from '@st/ui/components/StTabs/StTabs.vue'

const { t } = useI18n()

const { platform } = usePlatform()

const tabs = [
  {
    id: 'deposit',
    label: t('payments.wallet.replenish'),
  },
  {
    id: 'withdrawal',
    label: t('payments.wallet.withdrawal'),
  },
]

const router = useRouter()
const route = useRoute()

const selectedTabId = computed(() => route.query.tab?.toString() ?? 'deposit')

function handleTabClicked(newTabId: string | number) {
  router.replace({ query: { modal: 'payments', tab: newTabId } })
}

function close() {
  router.replace({ query: {} })
}
</script>

<style scoped>
.navigation-tabs {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-100);
  padding: var(--spacing-300) var(--spacing-300) var(--spacing-200);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-125);

  color: var(--text-primary);

  background-color: transparent;
  border: none;
  outline: none;

  &.close {
    margin-left: auto;
  }
}

.buy-wrapper {
  position: relative;
}

.soon {
  user-select: none;

  position: absolute;
  z-index: 2;
  top: -8px;
  right: -10px;

  padding: var(--spacing-025) var(--spacing-050);

  font: var(--desktop-text-xxs-medium);
  color: var(--text-secondary);

  background: var(--background-ghost-tertiary);
  border-radius: var(--border-radius-full, 999px);
}

.chevron-wrapper {
  position: absolute;
  z-index: 999;
  top: -12px;
  right: -8px;
}

.navigation-tabs.mobile {
  padding: var(--spacing-100);
  border-bottom: 0.5px solid var(--border-primary);

  .button {
    position: absolute;
    right: var(--spacing-050);

    &::before {
      content: '';

      position: absolute;
      z-index: 1;
      top: 5px;
      right: 27px;

      width: 36px;
      height: 30px;

      background: linear-gradient(270deg, #111118 0%, rgb(17 17 24 / 0%) 100%);
    }
  }

  .buy-wrapper {
    padding-bottom: 0;
  }

  .chevron-wrapper {
    position: static;
    display: flex;
    align-items: bottom;
    width: 24px;
  }
}
</style>
