import { useSettingsStore } from '@st/core/stores/useSettingsStore'

export function useFiatPaymentSystemsEnabled() {
  const { settings } = storeToRefs(useSettingsStore())

  const isFiatPaymentsSystemsEnabled = computed(
    // @ts-expect-error not ready
    () => settings.value.frontFiatPaymentSystemsEnabled,
  )

  return {
    isFiatPaymentsSystemsEnabled,
  }
}
