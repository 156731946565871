<template>
  <div class="input-with-select-wrapper">
    <div
      :class="wrapperClasses"
      class="input-with-select"
      data-t="input-with-select"
    >
      <h2>
        {{ title }}
      </h2>
      <div class="content">
        <div class="input-wrapper">
          <input
            v-model="inputModel"
            class="input"
            :placeholder="inputPlaceholder"
            :size="platform === 'desktop' ? 'l' : 'm'"
            :disabled="isDisabledInput"
            inputmode="decimal"
            @focus="handleFocus"
            @blur="handleBlur"
          />
        </div>
        <div class="select-wrapper">
          <StIcon
            :name="selectedItem?.icon"
            class="currency-icon"
            :size="platform === 'desktop' ? 24 : 20"
          />
          <div class="select-title">
            {{ selectedItem?.title }}
          </div>
          <StIcon name="lock-solid" class="lock-icon" size="16" />
        </div>
      </div>
    </div>
    <StTransitionExpand>
      <div v-if="error">
        <span v-if="error" class="error-message" data-t="error">
          {{ error }}
        </span>
      </div>
    </StTransitionExpand>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'

const emit = defineEmits<{
  (e: 'update:select', value: number): void
  (e: 'update:input', value: string): void
  (e: 'handleFocus'): void
}>()

const props = withDefaults(
  defineProps<{
    select: number
    input: string
    isDisabledSelect?: boolean
    isDisabledInput?: boolean
    title?: string
    inputPlaceholder?: string
    error?: string
    subtitle?: string
    selectItems: {
      id: number | string
      title: string
      icon: IconName
    }[]
  }>(),
  {
    isDisabledSelect: false,
    isDisabledInput: false,
  },
)

const { platform } = usePlatform()

const selectModel = computed({
  get() {
    return props.select
  },
  set(value: number) {
    emit('update:select', value)
  },
})

const inputModel = computed({
  get() {
    return props.input
  },
  set(value: string) {
    emit('update:input', value)
  },
})

const selectedItem = computed(
  () => props.selectItems?.find((item) => item.id === selectModel.value),
)

const isFocus = ref(false)

function handleFocus() {
  isFocus.value = true
  emit('handleFocus')
}

function handleBlur() {
  isFocus.value = false
}

const wrapperClasses = computed(() => ({
  [platform.value]: platform,
  focus: isFocus.value,
  error: props.error,
}))
</script>

<style scoped>
.input-with-select {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);

  height: 92px;
  padding: var(--spacing-200);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-150);

  &:hover {
    box-shadow: 0 0 0 1px var(--border-primary) inset;
  }

  &.focus {
    box-shadow: 0 0 0 1px var(--border-brand) inset;
  }

  &.error {
    box-shadow: 0 0 0 1px var(--system-error) inset;
  }
}

h2 {
  width: 100%;
  margin: 0;

  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);
  text-align: justify;

  span {
    float: right;

    padding-top: var(--spacing-100);

    font: var(--mobile-caption-1-regular);
    color: var(--text-secondary);
    text-align: left;
  }
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-wrapper {
  width: 100%;

  .input {
    font: var(--desktop-text-xl-semibold);
    color: var(--text-primary);

    background-color: transparent;
    border: none;
    border-radius: var(--border-radius-100);
    outline: none;
    caret-color: var(--text-primary);
  }
}

.clear-input {
  cursor: pointer;
  color: var(--text-tertiary);
}

.select-wrapper {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
}

.select-title {
  font: var(--desktop-text-xl-semibold);
}

.currency-icon {
  margin-right: var(--spacing-025);
}

.lock-icon {
  color: var(--icon-tertiary);
}

.error-message {
  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
}

.options {
  width: 1500px;
}

.mobile.input-with-select {
  gap: var(--spacing-075);
  height: 74px;
  padding: var(--spacing-150) var(--spacing-200);

  h2 {
    font: var(--mobile-text-medium);
  }

  .input {
    width: 100%;
    font: var(--mobile-title-1-semibold);
  }

  .select-title {
    font: var(--mobile-title-2-semibold);
  }
}
</style>
